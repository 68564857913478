<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div class="mt-6 ml-6">
    <v-row>
      <v-icon
        id="iiotNodeUpdateDetailsBackOnLogsButton"
        data-cy="iiotNodeUpdateDetailsBackOnLogsButton"
        large
        class="ml-n3 mt-6"
        @click="goToNodeLogPage()"
        >mdi-chevron-left
      </v-icon>
      <div class="title mt-6">
        <h1>
          {{ $t('nodeUpdateDetails.title', { operation_name: `${operationName}` }) }}
        </h1>
      </div>
    </v-row>
    <the-nerve-table
      id="iiotNodeUpdateDetailsList"
      :params="params"
      :columns="columns"
      :empty-state="$t('nodeUpdateDetails.nodeUpdateDetailsTable.emptyList')"
      :is-action-menu-enabled="false"
      custom-count-getter="getTotalDetails"
      :is-search-enabled="true"
      custom-list-getter="getNodeUpdateDetails"
      store-module="node-update-details"
      class="pr-6"
      @params-changed="updateParams"
    >
      <template #additional-actions>
        <v-row v-resize="onResize">
          <v-col
            id="iiotNodeUpdateDetailsListSuccessCheckbox"
            cols="4"
            lg="2"
            md="3"
            sm="3"
            class="mt-n1 d-flex justify-start align-center"
            :class="{ 'pb-0': isMarginVisible }"
          >
            <v-icon id="iiotNodeUpdateDetailsFilter"> mdi-filter-outline </v-icon>
            <v-checkbox v-model="params.isFinished" :label="$t('nodeUpdateDetails.success')" @click="filterBy" />
          </v-col>
          <v-col
            id="iiotNodeUpdateDetailsListInProgressCheckbox"
            cols="4"
            lg="3"
            sm="3"
            class="mt-n1 d-flex justify-center align-center"
            :class="{ 'pb-0': isMarginVisible }"
          >
            <v-checkbox v-model="params.inProgress" :label="$t('nodeUpdateDetails.inProgress')" @click="filterBy" />
          </v-col>
          <v-col
            id="iiotNodeUpdateDetailsListFailedCheckbox"
            cols="3"
            lg="2"
            sm="2"
            class="mt-n1 d-flex justify-center align-center"
            :class="{ 'pb-0': isMarginVisible }"
          >
            <v-checkbox v-model="params.isFailed" :label="$t('nodeUpdateDetails.failed')" @click="filterBy" />
          </v-col>
          <v-col
            id="iiotNodeUpdateDetailsListCancelledCheckbox"
            cols="3"
            lg="2"
            sm="2"
            :class="{
              'mt-n1 d-flex justify-end align-center': !isMarginVisible,
              'pt-0 d-flex justify-start align-center': isMarginVisible,
            }"
          >
            <v-checkbox v-model="params.isCanceled" :label="$t('nodeUpdateDetails.cancelled')" @click="filterBy" />
          </v-col>
        </v-row>
      </template>
      <template #additional-info>
        <div class="title">
          <h1>{{ $t('nodeUpdateDetails.operationTaskTitle') }}</h1>
        </div>
      </template>
    </the-nerve-table>
  </div>
</template>
<script>
import { TheNerveTable, ProgressLinearWithStatus } from 'nerve-ui-components';
import mqtt from '@/plugins/mqtt';

export default {
  components: {
    TheNerveTable,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    search: {
      type: String,
      default: '',
    },
    isFinished: {
      type: String,
      default: '',
    },
    isCanceled: {
      type: String,
      default: '',
    },
    inProgress: {
      type: String,
      default: '',
    },
    isFailed: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      params: {
        id: '',
      },
      operationName: '',
      isMarginVisible: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          text: this.$t('nodeUpdateDetails.nodeUpdateDetailsTable.device'),
          value: 'device',
          sortable: false,
        },
        {
          text: this.$t('nodeUpdateDetails.nodeUpdateDetailsTable.progress'),
          value: 'progress',
          width: '300px',
          sortable: false,
          component: {
            sfc: ProgressLinearWithStatus,
            props: {
              page: 'nodeUpdateDetails',
            },
          },
        },
        {
          text: this.$t('nodeUpdateDetails.nodeUpdateDetailsTable.retry'),
          value: 'retryCounterMax',
          sortable: false,
        },
        {
          text: this.$t('nodeUpdateDetails.nodeUpdateDetailsTable.started'),
          value: 'timeOfTaskStart',
          sortable: false,
        },
        {
          text: this.$t('nodeUpdateDetails.nodeUpdateDetailsTable.finished'),
          value: 'timeOfOperationFinish',
          sortable: false,
        },
        {
          text: this.$t('defaultTable.action'),
          value: '',
          width: '0%',
          sortable: false,
          component: {
            sfc: () => import('@/components/nodes/node-update-details/NodeUpdateDetailsTableAction.vue'),
            props: {
              iconsAction: ['MarkTaskAsFinish', 'DisabledMarkTaskAsFinish'],
            },
          },
        },
      ];
    },
  },
  async created() {
    this.params = {
      page: this.page || 1,
      itemsPerPage: this.itemsPerPage || 10,
      search: this.search || '',
      isFinished: this.isFinished !== 'undefined' ? this.isFinished === 'true' : true,
      inProgress: this.inProgress !== 'undefined' ? this.inProgress === 'true' : true,
      isFailed: this.isFailed !== 'undefined' ? this.isFailed === 'true' : true,
      isCanceled: this.isCanceled !== 'undefined' ? this.isCanceled === 'true' : true,
    };
    await mqtt.subscribeTo('cloud');
    await this.$store.dispatch('node-update-details/get_bulk_operation', window.location.pathname.split('/')[4]);
    this.content = this.$store.getters['node-update-details/bulkOperation'];
    await this.$store.dispatch('node-update-log/setType', { type: 'update_node' });
    // eslint-disable-next-line prefer-destructuring
    this.params.id = window.location.pathname.split('/')[4];
    this.operationName = this.content.operation_name;
    await this.$store.dispatch('node-update-details/fetch', this.params);
  },
  async beforeDestroy() {
    mqtt.unsubscribeFrom('cloud');
  },
  methods: {
    async filterBy() {
      this.params.page = 1;
      await this.$store.dispatch('node-update-details/fetch', this.params);
    },
    updateParams(parameters) {
      this.params = {
        ...parameters,
        isFinished: this.params.isFinished,
        isCanceled: this.params.isCanceled,
        inProgress: this.params.inProgress,
        isFailed: this.params.isFailed,
      };
      this.$router
        .push({
          name: 'Node Update Details',
          query: {
            page: parameters.page,
            itemsPerPage: parameters.itemsPerPage,
            search: parameters.search || undefined,
            isFinished: parameters.isFinished,
            isCanceled: parameters.isCanceled,
            inProgress: parameters.inProgress,
            isFailed: parameters.isFailed,
          },
        })
        .catch(() => {});
    },
    goToNodeLogPage() {
      this.$router.push({ name: 'Node Update Log', query: this.$store.getters['labels/getQuery'] });
    },
    onResize() {
      this.isMarginVisible = window.innerWidth < 600;
    },
  },
};
</script>
